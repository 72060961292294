export const NotFound = () => {
  return (
    <main
      style={{
        textAlign: 'center',
        marginTop: 100,
      }}
    >
      <b style={{ fontSize: 64 }}>404</b>
      <p>Przykro nam, ale nie znaleźliśmy tej strony</p>
    </main>
  );
};
