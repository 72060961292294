import { Component } from 'react';
import css from './Footer.module.css';
import phoneWhite from '../../images/icons/phoneWhite.svg';
import locationWhite from '../../images/icons/locationWhite.svg';
import envelopWhite from '../../images/icons/envelopWhite.svg';
class Footer extends Component {
  render() {
    return (
      <footer className={css.footer}>
        <div className={css.container}>
          <div className={css.footer__section}>
            <div className={css.logoWhite}>HORN</div>
            <div className={css.footer__box}>
              <address className={css.footer__address}>
                <a href="https://maps.app.goo.gl/gzyTJRniUNf9ydse8">
                  <img
                    src={locationWhite}
                    alt="Ikona pinezki lokalizacji"
                    className={css.icon}
                  />
                  ul. Piłsudskiego 24a, 34-730 Mszana Dolna
                </a>
              </address>

              <address className={css.footer__address}>
                <ul className={css.contactListFooter}>
                  <li className={css.contactListFooter__item}>
                    <img
                      src={envelopWhite}
                      alt="Ikona pinezki lokalizacji"
                      className={css.icon}
                    />
                    <a
                      className={css.contactListFooter__link}
                      href="mailto:biuro@horn.biz.pl"
                    >
                      biuro@horn.biz.pl
                    </a>
                  </li>
                  <li>
                    <img
                      src={phoneWhite}
                      alt="Ikona pinezki lokalizacji"
                      className={css.icon}
                    />
                    <a
                      className={css.contactListFooter__link}
                      href="tel:183319066"
                    >
                      18 33 19 0 66
                    </a>
                  </li>
                </ul>
              </address>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
