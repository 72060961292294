import { Banner } from 'components/Banner/Banner';
import { Promotion } from 'components/Promotion/Promotion';

export const Home = () => {
  return (
    <main>
      <Banner />
    </main>
  );
};
