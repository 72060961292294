import { Route, Routes } from 'react-router-dom';

import { Home } from 'pages/Home';
import { About } from 'pages/About';
import { Offer } from 'pages/Offer';
import { Gallery } from 'pages/Gallery';
import { Contact } from 'pages/Contact';
import { NotFound } from 'pages/NotFound';
import SharedLayout from './SharedLayout/SharedLayout';

export const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SharedLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="offer" element={<Offer />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
