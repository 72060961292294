import css from './Banner.module.css';
import React, { useEffect, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { logoBannerItems } from './logoBannerItems';
import { mainBannerItems } from './mainBannerItems';

export const Banner = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Style splide przy różnych szerokościach ekranu
  const mainSplideOptions = {
    width: '90vw',
    perPage: 1,
    rewindByDrag: true,
    type: 'loop',
    easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    padding: 0,
    gap: 0,
    autoplay: true,
    interval: 5000,
    pagination: false,
    focus: 'center',
  };
  const logoSplideOptions = {
    width: '90vw',
    perPage: 2,
    rewindByDrag: true,
    autoplay: true,
    interval: 2000,
    pauseOnHover: true,
    type: 'loop',
    easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    pagination: false,
    arrows: false,
  };
  if (windowWidth > 768) {
    mainSplideOptions.arrows = true;
    mainSplideOptions.padding = '20%';
    mainSplideOptions.gap = '10px';
    logoSplideOptions.perPage = 5;
    mainSplideOptions.width = '85vw';
    logoSplideOptions.width = '80vw';
  } else if (768 < windowWidth && windowWidth < 1200) {
    mainSplideOptions.padding = '10%';
  } else if (windowWidth > 1300) {
    mainSplideOptions.width = '60vw';
    logoSplideOptions.width = '60vw';
    // linijka wyżej
  } else if (windowWidth > 600) {
    logoSplideOptions.perPage = 3;
  } else {
    mainSplideOptions.arrows = false;
    mainSplideOptions.padding = 0;
  }

  return (
    <div className={css.mainPage}>
      <div className={css.sliderContainer}>
        <Splide
          aria-label="Główny baner"
          className={css.splide}
          options={mainSplideOptions}
        >
          {mainBannerItems.map((image, index) => (
            <SplideSlide key={index} className={css.splide__slide}>
              <div className={css.slide}>
                <picture>
                  <source
                    media="(max-width: 1300px)"
                    srcSet={image.mobileImg}
                  />
                  <img
                    src={image.original}
                    alt={image.description}
                    className={css.bannerImg}
                  />
                </picture>

                {image.link && (
                  <p className={css.imgLink}>
                    <a href={image.link}>Image by {image.author} on Freepik</a>
                  </p>
                )}
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <div className={css.logoSlider}>
        <Splide aria-label="Baner-loga" options={logoSplideOptions}>
          {logoBannerItems.map((image, index) => (
            <SplideSlide key={index}>
              <img
                src={image.original}
                alt={image.description}
                className={css.logoImg}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
