import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import css from './ContactSection.module.css';
import location from '../../images/icons/location.svg';
import envelop from '../../images/icons/envelop.svg';
import phone from '../../images/icons/phone.svg';
// import dotenv from 'dotenv';

// dotenv.config();
// const REACT_APP_MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
export const ContactSection = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: MAPS_API_KEY,
  });

  const [map, setMap] = React.useState(null);
  const [containerStyle, setContainerStyle] = useState({
    width: '300px',
    height: '300px',
  });

  const center = useMemo(() => {
    return {
      lat: 49.67632368385855,
      lng: 20.08378264701895,
    };
  }, []);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
    },
    [center]
  );

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 768) {
      setContainerStyle({ width: '300px', height: '300px' });
    } else if (windowWidth > 768 && windowWidth <= 1200) {
      setContainerStyle({ width: '500px', height: '300px' });
    } else if (windowWidth > 1200) {
      setContainerStyle({ width: '600px', height: '500px' });
    }
  }, [windowWidth]);

  return (
    <div className={css.container}>
      <div className={css.containers}>
        <div className={css.addressContainer}>
          <h3>Tu nas znajdziesz</h3>
          <div className={css.addressList}>
            <ul className={css.list}>
              <li>
                <address className={css.address}>
                  <img
                    src={location}
                    alt="Ikona pinezki lokalizacji"
                    className={css.iconLocalization}
                  />
                  ul. Piłsudskiego 24a, 34-730 Mszana Dolna
                </address>
              </li>
              <li>
                <div className={css.map}>
                  {isLoaded && (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={10}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                    >
                      {map && <Marker position={center} />}
                    </GoogleMap>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className={css.hoursContactContainer}>
          <div className={css.openHours}>
            <h3>Godziny otwarcia</h3>
            <ul className={css.dayList}>
              <li className={css.dayWrapper}>
                <span>Poniedziałek</span>
                <span>07:00–17:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Wtorek</span>
                <span>07:00–17:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Środa</span>
                <span>07:00–17:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Czwartek</span>
                <span>07:00–17:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Piątek</span>
                <span>07:00–17:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Sobota</span>
                <span>07:00–14:00</span>
              </li>
              <li className={css.dayWrapper}>
                <span>Niedziela</span>
                <span>Zamknięte</span>
              </li>
            </ul>
          </div>
          <div className={css.contact}>
            <h3>Kontakt</h3>
            <address>
              <ul className={css.contactList}>
                <li className={css.contactList__item}>
                  <a className={css.link} href="mailto:biuro@horn.biz.pl">
                    <img
                      src={envelop}
                      alt="Ikona koperty"
                      className={css.iconSmall}
                    />
                    biuro@horn.biz.pl
                  </a>
                </li>
                <li className={css.contactList__item}>
                  <a className={css.link} href="tel:183319066">
                    <img
                      src={phone}
                      alt="Ikona telefonu"
                      className={css.iconSmall}
                    />
                    18 33 19 0 66
                  </a>
                </li>
              </ul>
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContactSection);
