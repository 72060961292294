import React, { useEffect } from 'react';
import SimpleLightbox from 'simplelightbox/dist/simple-lightbox.esm';
import 'simplelightbox/dist/simple-lightbox.min.css';

import css from './GalleryComponent.module.css';
import { galleryItems } from './galleryItems';

export const GalleryComponent = () => {
  useEffect(() => {
    let lightbox = new SimpleLightbox('#gallery a', {});

    return () => lightbox.destroy();
  }, []);

  return (
    <div className={css.galleryContainer} id="gallery">
      {galleryItems.map((image, index) => (
        <a href={image.original} key={index} className={css.galleryItem}>
          <img
            src={image.preview}
            alt={image.description}
            className={css.galleryImg}
          />
        </a>
      ))}
    </div>
  );
};
