import React, { useState } from 'react';
import { Logo } from 'components/Logo/Logo';
import { Suspense } from 'react';
import { Outlet, Link } from 'react-router-dom';
import css from './SharedLayout.module.css';
import Footer from 'components/Footer/Footer';
import menuBtn from '../../images/icons/menu.svg';
import phone from '../../images/icons/phone.svg';
import envelop from '../../images/icons/envelop.svg';

const SharedLayout = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <div className={css.bodyContainer}>
        <header className={css.header}>
          <div className={css.container}>
            <div>
              <Logo />
            </div>
            <button
              type="button"
              className={css.menuBtn}
              onClick={toggleMenu}
              tabIndex="0"
            >
              <img src={menuBtn} alt="Menu button" />
            </button>

            {isMenuOpen && (
              <div className={css.mobileMenu}>
                <ul className={css.mobileNavList}>
                  <button
                    type="button"
                    className={css.closeBtn}
                    onClick={closeMenu}
                  >
                    X
                  </button>
                  <li>
                    <Link to="/" onClick={closeMenu}>
                      Strona główna
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={closeMenu}>
                      O nas
                    </Link>
                  </li>
                  <li>
                    <Link to="/offer" onClick={closeMenu}>
                      Oferta
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery" onClick={closeMenu}>
                      Galeria
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={closeMenu}>
                      Kontakt
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <nav>
              <ul className={css.navList}>
                <li>
                  <Link to="/">Strona główna</Link>
                </li>
                <li>
                  <Link to="/about">O nas</Link>
                </li>
                <li>
                  <Link to="/offer">Oferta</Link>
                </li>
                <li>
                  <Link to="/gallery">Galeria</Link>
                </li>
                <li>
                  <Link to="/contact">Kontakt</Link>
                </li>
              </ul>
            </nav>
            <ul className={css.contactList}>
              <li>
                <a href="mailto: biuro@horn.biz.pl">
                  {' '}
                  <img
                    src={envelop}
                    alt="Menu button"
                    className={css.contactIcon}
                  />
                  biuro@horn.biz.pl
                </a>
                <a href="tel: 18 33 19 0 66">
                  {' '}
                  <img
                    src={phone}
                    alt="Menu button"
                    className={css.contactIcon}
                  />
                  18 33 19 0 66
                </a>
              </li>
            </ul>
          </div>
        </header>

        <Suspense fallback={<div>Loading...</div>}>
          <Outlet />
        </Suspense>
      </div>{' '}
      <Footer />
    </>
  );
};
export default SharedLayout;
