import { Link } from 'react-router-dom';

import css from './Logo.module.css';

export const Logo = () => {
  return (
    <h2 className={css.logo}>
      <Link to="/">HORN-1</Link>
    </h2>
  );
};
