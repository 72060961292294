import css from './AboutComponent.module.css';
import sklep from './image/picture1.jpg';
export const AboutComponent = () => {
  return (
    <div className={css.container}>
      <img
        src={sklep}
        alt="Zdjęcie frontu sklepu i budynku, w którym się znajduje."
        className={css.image}
      />
      <div>
        <h3 className={css.title}>Horn "u Roga"</h3>
        <p className={css.paragraph}>
          {/* Jesteśmy firmą działającą na rynku od prawie 40 lat. Początkowo jako
          Firma Handlowa D.W. RÓG, a od 1995 jako Firma Handlowa "HORN-1".
          Posiadamy szeroką ofertę arktykułów chemicznych (farby, tynki, kleje,
          silikony, pianki), metalowych oraz elektronarzędzia. Prowadzimy
          również wypożyczalnię sprzętu. Dla udogodnienia klienta wprowadziliśmy
          sprzedaż ratalną. Klient ma możliwość negocjowania ceny oraz uzyskania
          rabatu. W miarę możliwości dowozimy towar na miejsce w obrębie gminy.
          Klient może liczyć na fachowe doradztwo oraz wizualizację i pomoc w
          podjęciu odpowiedniego wyboru. */}
          Jesteśmy firmą działającą na rynku od prawie 40 lat. Początkowo jako
          Firma Handlowa D.W. RÓG, a od 1995 jako Firma Handlowa "HORN-1".
          Oferujemy różnorodne artykuły chemiczne (farby, tynki, kleje,
          silikony, pianki), artykuły metalowe oraz elektronarzędzia. Dodatkowo
          prowadzimy wypożyczalnię sprzętu i umożliwiamy sprzedaż ratalną,
          negocjacje cen oraz rabaty. Wychodząc naprzeciw potrzebom klientów,
          oferujemy dowóz towaru w obrębie gminy. Naszym celem jest zapewnienie
          kompleksowej obsługi, dlatego klient może liczyć na fachowe doradztwo,
          wizualizację produktów i pomoc w dokonaniu właściwego wyboru.
          Dodatkowo, dla wygody klientów, dysponujemy własnym parkingiem.
          Dziękujemy za zaufanie przez te lata i serdecznie zapraszamy do
          skorzystania z naszej bogatej oferty.
        </p>
      </div>
    </div>
  );
};
