import electroTools from './images/electroTools.jpg';
import paints from './images/paints.jpg';
import silverNails from './images/silverNails.jpg';
import workClothes from './images/workClothes.jpg';
import tynkiKleje from './images/tynkiKleje.jpg';
import narzedziaBudowlane from './images/narzedziaBudowlane.jpg';
import szlifierka from './images/szlifierka.jpg';
import inne from './images/inne.jpg';

export const productsListItems = [
  {
    cardName: 'Wypożyczalnia sprzętu budowlanego',
    photo: szlifierka,
    photoDescription: 'Mężczyzna używający szlifierki',
    link: 'https://www.freepik.com/free-photo/professional-builder-work-clothes-works-with-cutting-tool_14284940.htm#page=2&query=contruction%20tools&position=0&from_view=search&track=ais&uuid=c21e40b8-5967-4cf5-9e5f-f70a90a517d8',
    author: 'pvproductions',
    text: 'Serdecznie informujemy, że w naszej ofercie dostępna jest również wypożyczalnia sprzętu budowlanego. Oferujemy szeroki zakres narzędzi i maszyn, które umożliwią skuteczne i profesjonalne wykonywanie różnorodnych prac budowlanych. Bez względu na rozmiar projektu, nasza wypożyczalnia zapewni dostęp do niezbędnego sprzętu, który ułatwi i przyspieszy realizację Twoich planów. ',
  },
  {
    cardName: 'Farby, lakiery, impregnaty, grunty',
    photo: paints,
    photoDescription: 'Puszki farby, wałki i pędzle do malowania',
    link: 'https://www.freepik.com/free-photo/top-view-paint-can_32055725.htm#page=2&query=paint%20shop&position=39&from_view=search&track=ais',
    text: 'Szeroki wybór kolorów, rodzajów i właściwości, aby sprostać różnorodnym potrzebom związanym z malowaniem i lakierowaniem. Farby firm: Dulux, Beckers, Magnat, Śnieżka, Scala oraz lakiery: Sadolin, Drewnochron, Nobiles, Hartzlack',
  },
  {
    cardName: 'Elektronarzędzia',
    photo: electroTools,
    photoDescription: 'Ręka mężczyzny trzymająca wiertarkę',
    link: 'https://www.freepik.com/free-photo/carpenter-holds-drill-drills-window_8971551.htm#query=electro%20tools&position=2&from_view=search&track=ais',
    author: 'jcomp',
    text: 'Zachęcamy do zapoznania się z naszym szerokim asortymentem elektronarzędzi, w tym produktami renomowanych marek, takimi jak Makita i Stalco. Oferujemy wysokiej jakości narzędzia, które spełnią oczekiwania nawet najbardziej wymagających klientów.',
  },
  {
    cardName: 'Narzędzia budowlane, glazurnicze, pneumatyczne, stolarskie',
    photo: narzedziaBudowlane,
    photoDescription: 'Mężczyzna używający piły tarczowej',
    link: 'https://www.freepik.com/free-photo/carpenter-working-with-circular-saw_11230073.htm#query=builders%20tools&position=21&from_view=search&track=ais&uuid=1c7441ae-c698-4eef-bac3-4e71ef32e8f7',
    author: 'gpointstudio',
    text: 'Bogata oferta, w której znajdziecie produkty renomowanych firm specjalizujących się w narzędziach budowlanych, glazurniczych, pneumatycznych oraz stolarskich, m.in.: RUBI, CMT, Kaufmann, TengTools, PIHER, Globus, fischer, STALCO',
  },
  {
    cardName: 'Śruby, wkręty, gwoździe i kołki',
    photo: silverNails,
    photoDescription: 'Wkręty',
    link: 'https://www.freepik.com/free-photo/silver-nails_5927154.htm#query=gwo%C5%BAdzie&position=19&from_view=search&track=ais',
    author: 'Racool_studio',
    text: 'Zapraszamy do odwiedzenia naszego sklepu i zapoznania się z pełnym asortymentem śrub, wkrętów i gwoździ. Jesteśmy pewni, że znajdziesz u nas odpowiednie elementy, które ułatwią i umożliwią skuteczne wykonanie Twoich projektów.',
  },
  {
    cardName: 'Odzież robocza',
    photo: workClothes,
    photoDescription: 'Dwójka ludzi w ubraniach roboczych',
    link: 'https://www.freepik.com/free-photo/two-colleagues-factory_4410803.htm#query=work%20clothes&position=24&from_view=search&track=ais',
    author: 'senivpetro',
    text: 'Niezależnie od tego, czy potrzebujesz odzieży ochronnej, kurtki roboczej, czy też komfortowych spodni do pracy, w naszym sklepie znajdziesz szeroki wybór odzieży, spełniającej najwyższe standardy jakości, m.in. renomowaną markę Lahti Pro.',
  },
  {
    cardName: 'Tynki, kleje ',
    photo: tynkiKleje,
    photoDescription: 'Mężczyzna ze szpachlą.',
    link: 'https://www.freepik.com/free-photo/plasterer-man-works-plastering-two-trowels-plasterboard-blue-uniform_2454882.htm#page=2&query=plaster&position=30&from_view=search&track=sph&uuid=d26f38b5-efd8-4474-9838-7e730e0bcb9a',
    author: 'freepic.diller',
    text: 'Oferujemy wysokiej jakości tynki i kleje od renomowanych firm: Bolix, Ceresit, KABE, ATLAS. Dzięki nim gwarantujemy solidność, trwałość i estetyczny wygląd Twoich prac wykończeniowych. Wybierz profesjonalizm z naszymi produktami.',
  },
  {
    cardName: 'Inne',
    photo: inne,
    photoDescription: 'Akcesoria malarskie, np.wałek do malowania, pędzel.',
    link: 'https://www.freepik.com/free-photo/flat-lay-arrangement-with-brushes-paint-tubes_6402461.htm#page=2&query=paint%20roller&position=13&from_view=search&track=ais&uuid=8d099e20-9764-4f2f-987f-718ca4b499a3',
    text: 'Nasz asortyment obejmuje  bogatą gamę innych produktów, takie jak paski klinowe, łożyska, artykuły do konserwacji i szpachlowania samochodów, pędzle, wałki, akcesoria malarskie, pianki, sylikony i więcej.',
  },
];
