import picture2 from './galleryItems/fullImg/picture2.jpg';
import picture3 from './galleryItems/fullImg/picture3.jpg';
import picture4 from './galleryItems/fullImg/picture4.jpg';
import picture5 from './galleryItems/fullImg/picture5.jpg';
import picture6 from './galleryItems/fullImg/picture6.jpg';
import picture7 from './galleryItems/fullImg/picture7.jpg';
import picture8 from './galleryItems/fullImg/picture8.jpg';
import picture9 from './galleryItems/fullImg/picture9.jpg';
import picture10 from './galleryItems/fullImg/picture10.jpg';
import picture11 from './galleryItems/fullImg/picture11.jpg';
import picture12 from './galleryItems/fullImg/picture12.jpg';
import picture13 from './galleryItems/fullImg/picture13.jpg';
import picture14 from './galleryItems/fullImg/picture14.jpg';
import picture15 from './galleryItems/fullImg/picture15.jpg';
import picture16 from './galleryItems/fullImg/picture16.jpg';
import picture17 from './galleryItems/fullImg/picture17.jpg';
import picture18 from './galleryItems/fullImg/picture18.jpg';
import picture19 from './galleryItems/fullImg/picture19.jpg';
import picture20 from './galleryItems/fullImg/picture20.jpg';

export const galleryItems = [
  {
    preview: picture14,
    original: picture14,
    description: 'Farby Dulux na półkach sklepowych',
  },
  {
    preview: picture15,
    original: picture15,
    description: 'Farby Dulux na półkach sklepowych, z próbkami farb',
  },
  {
    preview: picture16,
    original: picture16,
    description: 'Półki sklepowe z lakierami, farbami olejnymi',
  },
  {
    preview: picture17,
    original: picture17,
    description: 'Farby Dulux na półkach sklepowych',
  },
  {
    preview: picture12,
    original: picture12,
    description: 'Farby Dulux na półkach sklepowych',
  },
  {
    preview: picture13,
    original: picture13,
    description: 'Grunty na półkach sklepowych',
  },
  {
    preview: picture10,
    original: picture10,
    description: 'Lakiery na półkach sklepowych',
  },
  {
    preview: picture11,
    original: picture11,
    description: 'Kubły białej farby firmy Beckers',
  },
  {
    preview: picture2,
    original: picture2,
    description: 'Lakiery Drewnochron na półkach sklepowych',
  },
  {
    preview: picture3,
    original: picture3,
    description: 'Farby firmy Beckers',
  },
  {
    preview: picture9,
    original: picture9,
    description: 'Półki sklepowe z rozpuszczalnikami, gipsem',
  },
  {
    preview: picture4,
    original: picture4,
    description: 'Sprzęt firmy Makita',
  },
  {
    preview: picture18,
    original: picture18,
    description: 'Sprzęt firmy Makita',
  },
  {
    preview: picture19,
    original: picture19,
    description: 'Sprzęt firmy Makita',
  },
  {
    preview: picture20,
    original: picture20,
    description: 'Sprzęt firmy Makita',
  },
  {
    preview: picture6,
    original: picture6,
    description: 'Różnego typu narzędzia: klucze, miary zwijane',
  },
  {
    preview: picture5,
    original: picture5,
    description: 'Ubrania robocze firmy Lahti Pro',
  },

  {
    preview: picture7,
    original: picture7,
    description: 'Półka sklepowa',
  },
  {
    preview: picture8,
    original: picture8,
    description: 'Półka sklepowa',
  },
];
