import atlas from './images/loga/atlas.jpg';
import beckers from './images/loga/beckers.jpg';
import bolix from './images/loga/bolix.jpg';
import boll from './images/loga/boll.jpg';
import ceresit from './images/loga/ceresit.jpg';
import cmt from './images/loga/cmt.jpg';
import drewnochron from './images/loga/drewnochron.jpg';
import dulux from './images/loga/dulux.jpg';
import fischer from './images/loga/fischer.jpg';
import globus from './images/loga/globus.jpg';
import hartzlack from './images/loga/hartzlack.jpg';
import kaufmann from './images/loga/kaufmann.jpg';
import magnat from './images/loga/magnat.jpg';
import makita from './images/loga/makita.jpg';
import piher from './images/loga/piher.jpg';
import polifarb from './images/loga/polifarb.jpg';
import rubi from './images/loga/rubi.jpg';
import sadolin from './images/loga/sadolin.jpg';
import scala from './images/loga/scala.jpg';
import śnieżka from './images/loga/śnieżka.jpg';
import stalco from './images/loga/stalco.jpg';
import tengTools from './images/loga/tengTools.jpg';

export const logoBannerItems = [
  {
    preview: atlas,
    original: atlas,
    description: 'Logo firmy Atlas',
  },
  {
    preview: beckers,
    original: beckers,
    description: 'Logo firmy Beckers',
  },
  {
    preview: bolix,
    original: bolix,
    description: 'Logo firmy Bolix',
  },
  {
    preview: boll,
    original: boll,
    description: 'Logo firmy Boll',
  },
  {
    preview: ceresit,
    original: ceresit,
    description: 'Logo firmy Ceresit',
  },
  {
    preview: cmt,
    original: cmt,
    description: 'Logo firmy CMT',
  },
  {
    preview: drewnochron,
    original: drewnochron,
    description: 'Logo firmy Drewnochron',
  },
  {
    preview: dulux,
    original: dulux,
    description: 'Logo firmy Dulux',
  },
  {
    preview: fischer,
    original: fischer,
    description: 'Logo firmy Fischer',
  },
  {
    preview: globus,
    original: globus,
    description: 'Logo firmy Globus',
  },
  {
    preview: hartzlack,
    original: hartzlack,
    description: 'Logo firmy Hartzlack',
  },
  {
    preview: kaufmann,
    original: kaufmann,
    description: 'Logo firmy Kaufmann',
  },
  {
    preview: magnat,
    original: magnat,
    description: 'Logo firmy Magnat',
  },
  {
    preview: makita,
    original: makita,
    description: 'Logo firmy Makita',
  },
  {
    preview: piher,
    original: piher,
    description: 'Logo firmy Pichler',
  },
  {
    preview: polifarb,
    original: polifarb,
    description: 'Logo firmy Polifarb',
  },
  {
    preview: rubi,
    original: rubi,
    description: 'Logo firmy Ryobi',
  },
  {
    preview: sadolin,
    original: sadolin,
    description: 'Logo firmy Sadolin',
  },
  {
    preview: scala,
    original: scala,
    description: 'Logo firmy Scala',
  },
  {
    preview: śnieżka,
    original: śnieżka,
    description: 'Logo firmy Śnieżka',
  },
  {
    preview: stalco,
    original: stalco,
    description: 'Logo firmy Stalco',
  },
  {
    preview: tengTools,
    original: tengTools,
    description: 'Logo firmy TengTools',
  },
];
